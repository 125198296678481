<template>
    <v-tooltip
        :top="!bottom"
        :bottom="bottom"
        :disabled="!showSnackbar"
        color="primary"
    >
        <template v-slot:activator="{ on, attrs }">
            <div
                class="writer-avatar mr-4"
                v-bind="attrs"
                v-on="on"
            >
                <a
                    class="text-decoration-none black--text"
                    :href="`/writers/${id}`"
                    target="_blank"
                >
                    <avatar-rolles
                        :access-status="'writer'"
                        :firstname="firstname"
                        :lastname="lastname"
                        :avatar-url="avatarUrl"
                        :avatar-size="avatarSize"
                    />
                    <span
                        v-if="country"
                        class="user-avatar_flag"
                    >
                        <span :class="'flag-icon flag-input-icon flag-icon-' + country.toLowerCase()" />
                    </span>

                    <span
                        v-if="status === 'TRUSTED'"
                        class="writer-avatar_status"
                    >
                        <img
                            :src="TrustedImage"
                            :alt="status"
                        >
                    </span>
                </a>
            </div>
        </template>
        <div
            v-if="showSnackbar"
            class="z-99"
        >
            <p class="mb-0">
                <b>{{ nameCapitalized(firstname) }} {{ nameCapitalized(lastname) }}</b> <span v-if="nickname">| {{ nickname }}</span>
                <br>
                {{ id }}
            </p>
            <v-divider class="mt-2 pb-2" />
            <p class="mb-0">
                <span class="d-flex">
                    <span
                        v-if="country"
                        :class="'ml-1 flag-icon flag-input-icon flag-icon-' + country.toLowerCase()"
                    />
                    {{ countryNiceName }}
                </span>
            </p>
            <v-divider class="mt-2 pb-2" />
            <p class="mb-0">
                Status: <b>{{ status }}</b>
            </p>
            <p
                v-if="applied"
                class="mb-0"
            >
                Applied: <b>{{ $date.fromWithText(applied) }}</b>
            </p>
            <v-divider class="mt-2 pb-2" />
            <p
                v-if="order_limit"
                class="mb-0"
            >
                Projects limit: <b>{{ order_limit }}</b>
            </p>
            <v-divider class="mt-2 pb-2" />
            <p
                class="mb-0"
            >
                Feedbacks:
                <b>
                    <span class="font-weight-bold">{{ feedbacks_thirty_days_count || '-' }}</span>
                    <span style="font-size: 13px; margin-left: 5px">({{ feedbacks }})</span>
                </b>
            </p>
            <p class="mb-0">
                Rating:
                <b>
                    <span class="font-weight-bold">{{ rating_thirty_days || '-' }}</span>
                    <span style="font-size: 13px; margin-left: 5px">({{ rating }})</span>
                </b>
            </p>
        </div>
    </v-tooltip>
</template>

<script>

import TrustedImage from '@images/svg/trusted_writer.svg'
import UnTrustedImage from '@images/svg/new_writer.svg'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

import { nameCapitalized } from '@/helpers/capitalized/index'

export default {
    components: {
        AvatarRolles
    },
    props: {
        showSnackbar: {
            type: Boolean,
            default: true
        },
        firstname: {
            type: String,
            required: true
        },
        lastname: {
            type: String,
            required: true
        },
        nickname: {
            type: String,
            required: false
        },
        id: {
            type: Number,
            required: true
        },
        avatarUrl: {
            type: String,
            required: false,
            default: ''
        },
        avatarFallback: {
            type: String,
            required: false,
            default: ''
        },
        feedbacks: {
            type: Number,
            required: false,
            default: 0
        },
        feedbacks_thirty_days_count: {
            type: Number,
            required: false,
            default: 0
        },
        rating: {
            type: String,
            required: false,
            default: '0.00'
        },
        rating_thirty_days: {
            type: String,
            required: false,
            default: null
        },
        applied: {
            type: String,
            required: false,
            default: ''
        },
        status: {
            type: String,
            required: false,
            default: ''
        },
        avatarSize: {
            type: String,
            required: false
        },
        order_limit: {
            type: Number,
            required: false
        },
        country: {
            type: String,
            required: false
        },
        countryNiceName: {
            type: String,
            required: false
        },
        bottom: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        rankBackgroundColor() {
            if (this.rating >= 5 && this.rating < 9) {
                return 'rgba(240, 125, 0, 1)'
            } if (this.rating >= 9) {
                return 'rgba(39, 217, 31, 1)'
            }
            return 'rgba(255, 0, 0, 1)'
        },
        UnTrustedImage() {
            return UnTrustedImage
        },
        TrustedImage() {
            return TrustedImage
        }
    },
    methods: {
        nameCapitalized
    }
}
</script>

<style lang="scss">
    .writer-avatar{
        position: relative;
        height: max-content;
        width: max-content;
        &_rank{
            border-radius: 4px;
            padding: 3px;
            font-size: 12px;
            line-height: 1;
            color: #fff;
            font-weight: bold;
            position: absolute;
            // left: 33px;
            right: 0;
            transform: translateX(50%);
            white-space: nowrap;
            z-index: 1;
        }
        &_status{
            position: absolute;
            // bottom: 0;
            // left: 33px;
            left: -3px;
            width: 15px;
            height: 15px;
            background: #fff;
            border-radius: 50%;
            z-index: 1;
            img{
                max-width: 100%;
            }
        }
    }
    .user-avatar{
        position: relative;
        height: max-content;
        width: max-content;
        &_order-counter{
            background: #1976d2;
            border-radius: 4px;
            padding: 3px;
            font-size: 12px;
            line-height: 1;
            color: #fff;
            font-weight: bold;
            position: absolute;
            left: 33px;
            white-space: nowrap;
        }
        &_flag {
            width: 18px;
            height: 14px;
            border-radius: 4px;
            position: absolute;
            bottom: 0;
            left: 33px;
            overflow: hidden;
            .flag-icon{
                width: 100%;
                height: 100%;
                margin-right: 0;
            }
        }
    }
</style>
