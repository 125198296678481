<template>
    <v-bottom-navigation
        :fixed="true"
        horizontal
        dark
        color="accent"
        height="70px"
        class="backColor"
    >
        <template v-for="toolbar in toolbars">
            <v-btn
                v-if="!toolbar.subMenu"
                :key="toolbar.name"
                @click="emitEvent(toolbar.name, toolbar.modal)"
            >
                <span>{{ toolbar.text }}</span>

                <v-icon>{{ toolbar.icon }}</v-icon>
            </v-btn>
            <v-menu
                v-else
                :key="toolbar.name"
                top
                class="backColor"
                :offset-y="true"
                open-on-hover
                min-width="320px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>{{ toolbar.icon }}</v-icon>
                    </v-btn>
                </template>

                <v-list
                    class="backColor"
                    dark
                >
                    <v-list-item-group>
                        <template v-for="subItem in toolbar.subMenu">
                            <v-list-item
                                v-if="subItem.name !== 'divider'"
                                :key="subItem.name"
                                @click="emitEvent(subItem.name, subItem.modal)"
                            >
                                <v-list-item-icon>
                                    <v-icon v-text="subItem.icon" />
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ subItem.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider
                                v-else
                                :key="subItem.id"
                                class="my-2"
                            />
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </template>
    </v-bottom-navigation>
</template>

<script>
export default {
    props: {
        toolbars: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
        }
    },
    methods: {
        emitEvent(type, modal) {
            this.$emit('toolbarActivated', { type, modal })
        }
    }
}
</script>

<style lang="scss">
    .backColor{
        .v-btn.v-btn--active{
            color: #fff !important;
        }
    }
</style>
